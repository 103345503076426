import Base from '../components/Base.js';

export default function About() {
    return <Base>
        <p>Join us April 4-6, 2025 at <a href="https://www.hilton.com/en/hotels/ddhdhhf-hilton-boston-dedham/" target="_blank" rel="noreferrer">The Hilton Boston Dedham</a>, located in Boston, MA, USA for SudokuCon, a celebration of sudoku and pencil puzzles!</p>
        <p>Convention goers will have the opportunity to:</p>
        <ul>
            <li>Interact with solvers and setters from all over the world</li>
            <li>Improve your solving techniques</li>
            <li>Learn how to create puzzles in workshops (from beginner to advanced) led by some of your favorite setters</li>
            <li>Watch and participate in live speed solving and setting contests</li>
            <li>Feast together at a gala banquet with keynote speaker</li>
        </ul>
        <p>Some of the many experts expected to attend include:</p>
        <ul>
            <li>YouTube streamers Rangsk, Zetamath, and Tristan</li>
            <li>Popular setters clover, DiMono, FullDeck & Missing a Few Cards, Gliperal, Math Pesto, memeristor, Philip Newman, James Sinclair, and tallcat</li>
            <li>Sven Neumann, creator of SudokuPad</li>
        </ul>
        <p>The decisions behind SudokuCon are managed by the following team:</p>
        <ul>
            <li>carabet</li>
            <li>clover</li>
            <li>FullDeck</li>
            <li>Gliperal</li>
            <li>James Sinclair</li>
            <li>Math Pesto</li>
            <li>Missing a Few Cards</li>
            <li>MrDon</li>
            <li>Philip Newman</li>
            <li>ProfMeow</li>
            <li>Rangsk</li>
            <li>rockratzero</li>
            <li>SvenCodes</li>
            <li>whirlpion</li>
        </ul>
        <p>Tune in to our <a href="https://twitch.tv/sudokucon" target="_blank" rel="noreferrer">Twitch channel</a> for additional content throughout the year!</p>
    </Base>
}
