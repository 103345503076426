import { useState } from 'react';

import DonationTracker from '../components/DonationTracker';

function DonationBar() {
    const [donations, setDonations] = useState([]);

    let lastDonationStr = 'No donations yet';
    if (donations.length) {
        const lastDonation = donations[donations.length - 1];
        lastDonationStr = `Last donation: $${lastDonation.amount.toFixed(2)} by ${lastDonation.name ? lastDonation.name : 'Anonymous'}`
    }

    const raised = donations.reduce((sum, donation) => sum + donation.amount, 0);
    const progress = (raised / 50) + '%';

    let rightPart = <div className='goal' style={{ fontSize: '1.5em' }}> Donation goal: $3000</div>;
    if (raised < 500)
        rightPart = <div className='goal' style={{ fontSize: '1.5em', textAlign: 'center' }}>
            <div>Donation goal: $3000</div>
            <div>The first $50 of every donation will be matched!</div>
        </div>
    if (raised >= 3000)
        rightPart = <div className='goal' style={{ fontSize: '1.5em', textAlign: 'center' }}>
            <div>Our donation goal has been met!</div>
            <div>Stretch goal: $5000</div>
        </div>

    return <div style={{ width: '1920px', height: '80px', position: 'relative', fontFamily: 'Montserrat, Calibri, sans-serif' }}>
        <DonationTracker setDonations={setDonations} />
        <div className='background' style={{ position: 'absolute', width: '100%', height: '100%', background: '#EBC4FF', zIndex: -2 }} />
        <div className='progress' style={{ position: 'absolute', width: progress, height: '100%', background: '#FE7BFD', zIndex: -1 }} />
        <div className='container' style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '0 20px' }}>
            <div className='lastDonation' style={{ fontSize: '1.8em' }}>{lastDonationStr}</div>
            <div className='totalRaised' style={{ fontSize: '2.2em', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto' }}>Total raised: ${raised.toFixed(2)}</div>
            {rightPart}
        </div>
    </div>
}

export default DonationBar;
