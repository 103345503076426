import { useState, useEffect } from 'react';

import styles from './TransitAccordian.module.css';

function TransitAccordian(props) {
    const [open, setOpen] = useState(false);

    async function toggle() {
        setOpen(!open);
    }

    return <div className={styles.container}>
        <div className={open ? styles.upperOpen : styles.upperClosed} onClick={toggle}>{props.upper}</div>
        <div className={styles.lower} style={{display: open ? 'block' : 'none'}}>{props.lower}</div>
    </div>;
}

export default TransitAccordian;
