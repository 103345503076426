export async function createOrder(data, donationInfo)
{
    // Order is created on the server and the order id is returned
    return await fetch('/api/orders/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(donationInfo),
    });
};

export async function onApprove(data)
{
    // Order is captured on the server and the response is returned to the browser
    return await fetch(`/api/orders/${data.orderID}/capture`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: ''
    })
};
