import { useState, useEffect } from 'react';
import Base from '../components/Base.js';

const LIVESTREAM_END = process.env.REACT_APP_LIVESTREAM_END;

function Schedule() {
    const [schedule, setSchedule] = useState(null);

    async function loadSchedule() {
        const res = await fetch('/api/schedule');
        const scheduleData = await res.json();
        scheduleData.sort((a, b) => (new Date(a.start) - new Date(b.start)));
        setSchedule(scheduleData);
    }

    useEffect(() => {
        loadSchedule();
    }, []);

    function formatDate(date) {
        date = new Date(date);
        const month = 'Nov'; // for our purposes
        const day = date.getDate();
        const time = date.toLocaleTimeString().replace(':00 ', ' ');
        return `${month} ${day} ${time}`;
    }

    if (LIVESTREAM_END < Date.now() / 1000)
        return <Base>
            <center><h2>Livestream schedule</h2></center>
            The current event has ended. Catch us next time!
        </Base>

    if (!schedule)
        return <Base>Loading schedule...</Base>;

    return <Base>
        <table style={{ width: '100%' }}>
            <tr>
                <th>Start</th>
                <th>End</th>
                <th>Activities</th>
            </tr>
            {schedule.map((block, i) => {
                return <tr style={i % 2 ? { background: '#DBB4DB' } : { background: '#FBD4FB' }}>
                    <td style={{ padding: '0.2em 0.5em' }}>{formatDate(block.start)}</td>
                    <td style={{ padding: '0.2em 0.5em' }}>{formatDate(block.end)}</td>
                    <td style={{ padding: '0.2em 0.5em' }}><table style={{ cellPadding: 0, cellSpacing: 0 }}>
                        <tr><td>{block.events}</td></tr>
                        <tr><td style={{ fontSize: '0.7em' }}>Hosted by: {block.emcees}</td></tr>
                    </table></td>
                </tr>
            })}
        </table>
        <center>
            <p>All dates/times converted to your local timezone.</p>
        </center>
    </Base>
}

export default Schedule;
