import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';
import Countdown from '../components/Countdown.js';
import styles from './Home.module.css';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';

const LIVESTREAM_START = process.env.REACT_APP_LIVESTREAM_START;
const LIVESTREAM_END = process.env.REACT_APP_LIVESTREAM_END;

function Home()
{
    let livestreamElements = null;
    const now = Date.now() / 1000;
    if (
        LIVESTREAM_START &&
        LIVESTREAM_END &&
        now > LIVESTREAM_START - 2419200 && // four weeks before livestream starts
        now < LIVESTREAM_END + 1209600 // two weeks after livestream ends
    ) {
        if (now > LIVESTREAM_END)
            livestreamElements = <>
                <div>The 24 hour fundraising livestream has ended, but you can still check out the highlights and solve the puzzles on our <a href="/stream">stream page</a>!</div>
            </>;
        else
            livestreamElements = <>
                {now > LIVESTREAM_START
                    ? <center style={{ fontSize: '1.3em' }}>24 hour fundraising livestream is live now!</center>
                    : <>
                        <center style={{ fontSize: '1.3em' }}>24 hour fundraising livestream will be live in:</center>
                        <Countdown className={styles.countdown} endTime={LIVESTREAM_START} />
                    </>
                }
                <div className={styles.buttonsContainer}>
                    <a href="/schedule" style={{display: 'flex'}}><div className={styles.livestreamButton}>Schedule</div></a>
                    <a href="https://twitch.tv/sudokucon" target="_blank" rel="noreferrer" style={{display: 'flex'}}><div className={styles.livestreamButton}>Watch on Twitch</div></a>
                </div>
                <div className={styles.tpc}>
                    <div className={styles.twitchPlayer}>
                        <TwitchPlayer
                            channel="SudokuCon"
                            width="100%"
                            height="100%"
                            onOnline={() => { }}
                            onOffline={() => { }}
                        />
                    </div>
                </div>
            </>;
    }

    return <Base>
        {livestreamElements}
        <p>Interested in what we're doing? <a href="https://sudokucon.beehiiv.com/" target="_blank" rel="noreferrer">Check out our newsletter!</a></p>
        <p>SudokuCon is supported by:</p>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5%', padding: '2em 0em' }}>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} alt="The Tidings of Minos" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} alt="Artisanal Sudoku" width='100%' />
                </a>
            </div>
        </div>
    </Base>
}

export default Home;
