import { TwitchPlayer } from 'react-twitch-embed';

import Base from '../components/Base.js';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';

export default function Livestream()
{
    return <Base>
        <p>Check out some of the highlights of last year's fundraiser on our Twitch channel:</p>
        <div style={{ width: "48vw", "min-width": "400px", height: "27vw", "min-height": "300px" }}>
            <TwitchPlayer
                channel="SudokuCon"
                width="100%"
                height="100%"
                onOnline={() => { }}
                onOffline={() => { }}
            />
        </div>

        <p>We also owe some special thanks to the many people who helped with the 2023 SudokuCon fundraiser! Here are some shoutouts to the many capable and generous people who helped out, and some links to their additional puzzle projects:</p>

        <h2>Puzzles</h2>

        <p>Many talented setters have created puzzles for SudokuCon already, and we haven't been able to share all of them on stream. Download the <a href="./SudokuCon_Puzzle_Book_2023.pdf" target="_blank">SudokuCon puzzle book</a> for free, containing over 100 puzzles from dozens of setters.</p>

        <h2>Sponsors</h2>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5%' }}>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} width='100%' />
                </a>
            </div>
        </div>

        <h2>Tech</h2>

        Gliperal
        <br/>Rangsk &nbsp;&#9679;&nbsp; <a href="" target="_blank" rel="noreferrer">https://youtube.com/@rangsk</a>
        <br/>Sven Neumann &nbsp;&#9679;&nbsp; <a href="https://" target="_blank" rel="noreferrer">svencodes.com/</a>
        <br/>Whirlpion

        <h2>Streamers</h2>

        <div style={{ wordBreak: 'break-word' }}>
        Bill Murphy &nbsp;&#9679;&nbsp; <a href="https://patreon.com/wwmsudoku" target="_blank" rel="noreferrer">patreon.com/wwmsudoku</a>
        <br/>Carabet &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/sudokuconcarabet" target="_blank" rel="noreferrer">twitch.tv/sudokuconcarabet</a>
        <br/>Clover &nbsp;&#9679;&nbsp; <a href="https://patreon.com/sudokuclover" target="_blank" rel="noreferrer">patreon.com/sudokuclover</a>
        <br/>FullDeck & Missingafewcards &nbsp;&#9679;&nbsp; <a href="https://missingdeck.net/" target="_blank" rel="noreferrer">missingdeck.net</a>
        <br/>James Sinclair &nbsp;&#9679;&nbsp; <a href="https://artisanalsudoku.substack.com/" target="_blank" rel="noreferrer">artisanalsudoku.substack.com</a>
        <br/>Jonn
        <br/>Kodra &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/kodra22" target="_blank" rel="noreferrer">twitch.tv/kodra22</a>
        <br/>Memeristor &nbsp;&#9679;&nbsp; <a href="https://www.youtube.com/@memeristor" target="_blank" rel="noreferrer">www.youtube.com/@memeristor</a>
        <br/>Niverio &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/niverio24" target="_blank" rel="noreferrer">twitch.tv/niverio24</a>
        <br/>Philip Newman &nbsp;&#9679;&nbsp; <a href="https://patreon.com/sudokutheory" target="_blank" rel="noreferrer">patreon.com/sudokutheory</a>
        <br/>Prasanna Seshadri &nbsp;&#9679;&nbsp; <a href="https://logicmastersindia.com/expo/" target="_blank" rel="noreferrer">logicmastersindia.com/expo</a>
        <br/>Qodec &nbsp;&#9679;&nbsp; <a href="https://twitch.tv/puzzleqodec" target="_blank" rel="noreferrer">twitch.tv/puzzleqodec</a>
        <br/>Scojo
        <br/>Sven Neumann &nbsp;&#9679;&nbsp; <a href="https://svencodes.com/" target="_blank" rel="noreferrer">svencodes.com</a>
        <br/>Tantan Dai
        <br/>The Bear That Wasn't
        <br/>Wessel Strijkstra &nbsp;&#9679;&nbsp; <a href="https://puzz.link/db/?type=kouchoku&via=WStrijkstra" target="_blank" rel="noreferrer">puzz.link/db/?type=kouchoku&via=WStrijkstra</a>
        <br/>Zetamath & Tristan &nbsp;&#9679;&nbsp; <a href="https://youtube.com/@zetamathdoespuzzles" target="_blank" rel="noreferrer">youtube.com/@zetamathdoespuzzles</a>

        <h2>Content Contributors</h2>

        99% Sneaky
        <br/>Akash Doulani &nbsp;&#9679;&nbsp; <a href="https://akashdoulani.blogspot.com/" target="_blank" rel="noreferrer">akashdoulani.blogspot.com</a>
        <br/>crusader175 &nbsp;&#9679;&nbsp; <a href="https://www.youtube.com/@CrusaderPuzzle" target="_blank" rel="noreferrer">www.youtube.com/@CrusaderPuzzle</a>
        <br/>Craig Kasper
        <br/>KenGlue
        <br/>Malrog &nbsp;&#9679;&nbsp; <a href="https://logic-masters.de/Raetselportal/Benutzer/allgemein.php?name=Malrog" target="_blank" rel="noreferrer">logic-masters.de/Raetselportal/Benutzer/allgemein.php?name=Malrog</a>
        <br/>Microstudy &nbsp;&#9679;&nbsp; <a href="https://drive.google.com/file/d/1tOAQMy8Wl8DFUe5X5rVgFpr_I1g9ohUA" target="_blank" rel="noreferrer">drive.google.com/file/d/1tOAQMy8Wl8DFUe5X5rVgFpr_I1g9ohUA</a>
        <br/>rockratzero
        <br/>shye &nbsp;&#9679;&nbsp; Contact shye (@shyeheya) on Discord for custom puzzle commissions!
        <br/>thelukeskywalker2
        <br/>thetearex &nbsp;&#9679;&nbsp; <a href="https://tinyurl.com/233y3r45" target="_blank" rel="noreferrer">tinyurl.com/233y3r45</a>
        </div>
    </Base>
}
