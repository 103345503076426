import { useState, useEffect } from 'react';

function Countdown(props)
{
    const [countdown, setCountdown] = useState(null);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (timer !== null)
            clearInterval(timer);
        const endTime = props.endTime ? props.endTime : 1699120800;
        setCountdown(endTime - Math.floor(Date.now() / 1000));
        const interval = setInterval(() => {
            const endTime = props.endTime ? props.endTime : 1699120800;
            setCountdown(endTime - Math.floor(Date.now() / 1000));
        }, 1000);
        setTimer(interval);
    }, [props.endTime]);

    const c = countdown < 0 ? 0 : countdown;
    const seconds = Math.floor(c) % 60;
    const minutes = Math.floor(c / 60) % 60;
    const hours = Math.floor(c / 3600) % 24;
    const days = Math.floor(c / 86400);
    let countdownStr;
    if (props.format === 'H:MM:SS')
        countdownStr = (days * 24 + hours) + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    else if (props.format === 'H:MM')
        countdownStr = (days * 24 + hours) + ':' + minutes.toString().padStart(2, '0');
    else if (props.format === 'HH:MM')
        countdownStr = (days * 24 + hours).toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    else
        countdownStr = days + ':' + hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

    return <div className={props.className}>{countdownStr}</div>;
}

export default Countdown;
