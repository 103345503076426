import { useState } from 'react';

import DonationTracker from '../components/DonationTracker';

function Donations() {
    const [donations, setDonations] = useState([]);
    const [incentives, setIncentives] = useState([]);

    async function updateIncentives() {
        const res = await fetch('/api/incentives');
        const incentivesRaw = await res.json();
        incentivesRaw.filter(x => x.type === 'bidwar').forEach(bidwar => {
            const options = incentivesRaw.filter(x => x.id.startsWith(bidwar.id + ':'));
            bidwar.options = options;
            options.forEach(option => {
                option.bidwarOption = true;
                if (!('raised' in option))
                    option.raised = 0;
                if (option.type === 'puzzle')
                    option.title = `"${option.name}" by ${option.author}`;
            });
            options.sort((a, b) => b.raised - a.raised);
        });
        setIncentives(incentivesRaw.filter(x => !(x.bidwarOption)));
    }

    const reverseDonations = donations.slice().reverse();

    return <center>
        <DonationTracker setDonations={newDonations => { setDonations(newDonations); updateIncentives(); }} />
        <div style={{ display: 'flex' }}>
            <div>
                {reverseDonations.map(donation => <div style={{ border: '1px solid black', padding: '5px' }} key={JSON.stringify(donation)}>
                    Name: {donation.name}<br/>
                    Amount: {donation.amount}<br/>
                    Message: {donation.message}<br/>
                    Extra info: {donation.extraInfo}<br/>
                    Incentive: {donation.incentive}<br/>
                    Time: {new Date(donation.time).toLocaleString()}
                </div>)}
            </div>
            <div>
                {incentives.filter(x => (x.type === 'bidwar')).map(bidwar => <div style={{ padding: '5px' }} key={bidwar.title}>
                    Bidwar: {bidwar.title}
                    {bidwar.options.map(option => <div style={{ border: '1px solid black', padding: '5px' }} key={option.id}>
                        {option.title}<br/>
                        Raised: {option.raised}
                    </div>)}
                </div>)}
            </div>
            <div>
                {incentives.filter(x => x.goal).map(incentive => <div style={{ border: '1px solid black', padding: '5px' }} key={incentive.title}>
                    Incentive: {incentive.title}<br/>
                    Raised: {incentive.raised ? incentive.raised : 0} / {incentive.goal}
                </div>)}
            </div>
        </div>
    </center>
}

export default Donations;
